/*
 created by Mussa Charles @ 9/3/2022 https://mussacharles60.com
 */

 export { };

 export interface OnWindowLocationChanged {
     (path: string): void;
 }
 
 type EventObject = {
     name?: string;
 }
 
 export default class WindowLocationHandler {
 
     // constructor() { }
 
     public static subscribe(event: EventObject, callback: OnWindowLocationChanged) {
         if (!event.name) event.name = 'location-changed';
         //TODO reference https://dirask.com/posts/JavaScript-on-location-changed-event-on-url-changed-event-DKeyZj
         const pushState: any = window.history.pushState;
         const replaceState: any = window.history.replaceState;
 
         window.history.pushState = function () {
             pushState.apply(window.history, arguments);
             if (!event.name) event.name = 'location-changed';
             window.dispatchEvent(new Event("pushstate"));
             window.dispatchEvent(new Event(event.name));
         }
         window.history.replaceState = function () {
             replaceState.apply(window.history, arguments);
             if (!event.name) event.name = 'location-changed';
             window.dispatchEvent(new Event("replacestate"));
             window.dispatchEvent(new Event(event.name));
         }
 
         // on window back button click | just listen on go back button click
         window.onpopstate = (_event: any) => {
             if (!event.name) event.name = 'location-changed';
             window.dispatchEvent(new Event(event.name));
         };
 
         // window.addEventListener('hashchange', () => {
         //   Console.debug('onhashchange event occurred!');
         // });
 
         // listen on location change
         window.addEventListener(event.name, () => {
             // Console.debug("locationchange event occurred!");
             callback(window.location.pathname);
         });
     }
 
     public static unsubscribe(event: EventObject) {
         if (!event.name) event.name = 'location-changed';
         window.removeEventListener(event.name, () => { });
     }
 }