import './index.scss';

import * as H from 'history';

import { User, UserVotingInfo } from '../../models';

import DatabaseManager from '../../service';
import { FaTimes } from 'react-icons/fa';
import React from 'react';
import Routes from '../../utils/routes';
import VotingItem from './voting-item';

type VotingPageProps = {
    history: H.History;
    current_user: User;
    refresh_id: number;
    onSubmit: (is_submitting: boolean) => void;
}

type VotingPageState = {
    error: string | null;
    isLoading: boolean;
    voting: UserVotingInfo | null;
    proxies: UserVotingInfo[];
}

class VotingPage extends React.Component<VotingPageProps, VotingPageState> {

    private didMount: boolean = false;
    private refresh_id: number = 0;
    private databaseManager: DatabaseManager = new DatabaseManager();


    constructor(props: VotingPageProps) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            voting: null,
            proxies: [],
        }
    }

    componentDidMount() {
        this.didMount = true;
        this.refresh_id = this.props.refresh_id;
        this.getVotingInfo();
    }

    componentDidUpdate(prevProps: VotingPageProps, _prevState: VotingPageState) {
        if (this.refresh_id !== prevProps.refresh_id) {
            this.refresh_id = this.props.refresh_id;
            // update functions from here
            this.getVotingInfo();
        }
    }

    componentWillUnmount() {
        this.didMount = false;
    }

    private getVotingInfo() {
        this.setState({ isLoading: true });
        this.databaseManager.getVotingInfo(this.props.current_user.id, {
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                console.log('getVotingInfo: result', result);
                if (result && result.success) {
                    // this.props.onShow();
                    if (result.success.data && result.success.data.length > 0) {
                        const data = result.success.data[0];
                        // console.log('getVotingInfo: data', data);
                        const voting: UserVotingInfo = {
                            user_id: data.user_id ? data.user_id : '',
                            user_name: data.user_name ? data.user_name : '',
                            user_balance: data.user_balance ? data.user_balance : 0,
                            user_type: data.user_type ? data.user_type : 'guest',
                            voting_category: data.voting_category ? data.voting_category : '',
                            candidates: data.candidates ? data.candidates : [],
                            // proxies: data.proxies ? data.proxies : [],
                        };
                        const proxies: UserVotingInfo[] = [];
                        if (data.proxies && data.proxies.length > 0) {
                            data.proxies.forEach((proxy_voting_info: any) => {
                                proxies.push({
                                    user_id: proxy_voting_info.user_id ? proxy_voting_info.user_id : '',
                                    user_name: proxy_voting_info.user_name ? proxy_voting_info.user_name : '',
                                    user_balance: proxy_voting_info.user_balance ? proxy_voting_info.user_balance : 0,
                                    user_type: proxy_voting_info.user_type ? proxy_voting_info.user_type : 'guest',
                                    voting_category: proxy_voting_info.voting_category ? proxy_voting_info.voting_category : '',
                                    candidates: proxy_voting_info.candidates ? proxy_voting_info.candidates : [],
                                });
                            });
                        }
                        this.setState({ voting, proxies });
                    }
                }
                else {
                    if (result.error && result.error.message) {
                        this.setState({ error: result.error.message });
                    }
                }
            },
            onError: (error: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                console.log('getVotingInfo: error', error);
            }
        });
    }

    render() {
        return (
            <div className='voting-lay'>
                <div className='voting-lay-inner'>
                    <div className='voting-lay-inner-body'>
                        {this.state.isLoading && <div className='voting-loading-icon def-loading-spinner' />}
                        {this.state.error && <div className='voting-lay-error'>
                            <span className='voting-lay-error-text'>{this.state.error}</span>
                        </div>}
                        {this.state.voting && 
                        (this.state.voting.user_type === 'shareholder' || this.state.voting.user_type === 'shareholder_proxy') &&
                            <VotingItem
                                key={'user'}
                                index={'user'}
                                voting={this.state.voting}
                                onSubmit={this.props.onSubmit}
                            />
                        }
                        {this.state.proxies.length > 0 &&
                            <>
                                <div className='voting-lay-proxies-title'> Proxies </div>
                                {this.state.proxies.map((proxy: UserVotingInfo, index: number) => {
                                    return (
                                        <VotingItem
                                            key={'proxy-' + index}
                                            index={'proxy-' + index}
                                            voting={proxy}
                                            onSubmit={this.props.onSubmit}
                                        />
                                    );
                                })}
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default VotingPage;

