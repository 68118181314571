
const Routes = {
    Landing: '/',
    Login: '/login',
    Register: '/register',
    Home: '/home',
    Candidates: '/candidates',
    Voting: '/voting',
    Help: '/help',
    Privacy: '/privacy',
    Terms: '/terms',
    Account: '/account',
}

export default Routes;