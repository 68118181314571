import './index.scss';

import * as H from 'history';

import { Link, match, withRouter } from 'react-router-dom';
import { User, UserProxy } from '../../models';

import AccountPage from '../account';
import CandidatePage from '../candidates';
import DatabaseManager from '../../service';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import React from 'react';
import Routes from '../../utils/routes';
import Themer from '../../utils/themer';
import VotingPage from '../voting';
import WindowLocationHandler from '../../utils/location-handler';

type Props = {
    history: H.History;
    location: H.Location;
    match: match<{}>;
}

type State = {
    show_modal: boolean;
    current_path: string;
    page_title: string;
    current_user: User | null;
    isLoading: boolean;
    feeds_refresh_id: number;
    voting_refresh_id: number;
    candidate_refresh_id: number;
    account_refresh_id: number;
    is_submitting: boolean;
    show_confirm_modal: boolean;
}

class Main extends React.PureComponent<Props, State> {

    private didMount: boolean = false;
    private databaseManager: DatabaseManager = new DatabaseManager();
    private current_user_id: string | null = null;
    private selected_item_id: string | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            show_modal: false,
            current_path: this.props.location.pathname,
            page_title: 'Laibon - Device - Home',
            current_user: null,
            isLoading: true,
            feeds_refresh_id: 0,
            voting_refresh_id: 0,
            candidate_refresh_id: 0,
            account_refresh_id: 0,
            is_submitting: false,
            show_confirm_modal: false,
        };
    }

    componentDidMount() {
        this.didMount = true;
        this.current_user_id = sessionStorage.getItem('laibon-uid');
        if (!this.current_user_id) {
            this.props.history.push(Routes.Login);
        }

        sessionStorage.removeItem('laibon-uid');

        this.switchPage(this.state.current_path);
        WindowLocationHandler.subscribe({}, (path: string) => {
            if (!this.didMount) return;
            this.setState({ current_path: path, show_modal: false });
            this.switchPage(path);;
        });
        this.getUserDetails();
        window.addEventListener('confirm-submit-vote', (event: any) => {
            if (!this.didMount) return;
            this.selected_item_id = event.detail.item_id ? event.detail.item_id : null;
            this.setState({ show_confirm_modal: true });
        });
        Themer.subscribe();
    }

    componentWillUnmount() {
        this.didMount = false;
        WindowLocationHandler.unsubscribe({});
        window.removeEventListener('confirm-submit-vote', () => { });
        Themer.unsubscribe();
    }

    switchPage = (path: string) => {
        this.setState({ current_path: path })
        if (path.startsWith(Routes.Home)) {
            this.setState({
                page_title: 'Laibon - Device - Home',
            });
        }
        else if (path.startsWith(Routes.Account)) {
            this.setState({
                page_title: 'Laibon - Device - Account',
            });
        }
        else if (path.startsWith(Routes.Candidates)) {
            this.setState({
                page_title: 'Laibon - Device - Candidates',
            });
        }
        else if (path.startsWith(Routes.Voting)) {
            this.setState({
                page_title: 'Laibon - Device - Voting',
            });
        }
    }

    private getUserDetails() {
        if (!this.current_user_id) {
            this.props.history.push(Routes.Login);
            return;
        }
        this.setState({ isLoading: true });
        this.databaseManager.getUserDetails(this.current_user_id, {
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                // console.log('getUserDetails', result);
                if (result && result.success && result.success.data && result.success.data.length > 0) {
                    const data = result.success.data[0];
                    const proxies: UserProxy[] = [];
                    const user: User = {
                        id: data.id ? data.id : "",
                        name: data.name ? data.name : "",
                        email: data.email ? data.email : "",
                        phone: data.phone ? data.phone : "",
                        client_id: data.client_id ? data.client_id : "",
                        client_type: data.client_type ? data.client_type : "",
                        address_1: data.address_1 ? data.address_1 : "",
                        address_2: data.address_2 ? data.address_2 : "",
                        address_3: data.address_3 ? data.address_3 : "",
                        city: data.city ? data.city : "",
                        contact_person: data.contact_person ? data.contact_person : "",
                        fax_number: data.fax_number ? data.fax_number : "",
                        instrument: data.instrument ? data.instrument : "",
                        issuer: data.issuer ? data.issuer : "",
                        balance: data.balance ? data.balance : 0,
                        created_at: data.created_at ? data.created_at : 0,
                        type: data.type ? data.type : "",
                        represented: data.represented ? data.represented : false,
                        represented_by: data.represented_by ? data.represented_by : "",
                        attend_type: data.attend_type ? data.attend_type : "",
                        attended: data.attended ? data.attended : false,
                        attended_at: data.attended_at ? data.attended_at : 0,
                        login_type: data.login_type ? data.login_type : "",
                        sessions: data.sessions ? data.sessions : [],
                        proxies: [],
                    }
                    if (data.proxies && data.proxies.length > 0) {
                        data.proxies.forEach((proxy: UserProxy) => {
                            const newProxy: UserProxy = {
                                id: proxy.id ? proxy.id : "",
                                name: proxy.name ? proxy.name : "",
                                email: proxy.email ? proxy.email : "",
                                phone: proxy.phone ? proxy.phone : "",
                                client_id: proxy.client_id ? proxy.client_id : "",
                                client_type: proxy.client_type ? proxy.client_type : "",
                                address_1: proxy.address_1 ? proxy.address_1 : "",
                                address_2: proxy.address_2 ? proxy.address_2 : "",
                                address_3: proxy.address_3 ? proxy.address_3 : "",
                                city: proxy.city ? proxy.city : "",
                                contact_person: proxy.contact_person ? proxy.contact_person : "",
                                fax_number: proxy.fax_number ? proxy.fax_number : "",
                                instrument: proxy.instrument ? proxy.instrument : "",
                                issuer: proxy.issuer ? proxy.issuer : "",
                                balance: proxy.balance ? proxy.balance : 0,
                                created_at: proxy.created_at ? proxy.created_at : 0,
                                type: proxy.type ? proxy.type : "shareholder",
                                represented: proxy.represented ? proxy.represented : false,
                                represented_by: proxy.represented_by ? proxy.represented_by : "",
                                attend_type: proxy.attend_type ? proxy.attend_type : "",
                                attended: proxy.attended ? proxy.attended : false,
                                attended_at: proxy.attended_at ? proxy.attended_at : 0,
                            }
                            proxies.push(newProxy);
                        });
                        user.proxies = proxies;
                    }
                    // localStorage.setItem("laibon-usr", JSON.stringify(user));
                    // this.context.setUser(user);
                    this.setState({
                        current_user: user,
                        feeds_refresh_id: this.state.feeds_refresh_id + 1,
                        voting_refresh_id: this.state.voting_refresh_id + 1,
                    });
                }
                else {
                    // if (result && result.error) {
                    //     this.setState({ error: result.error.message ? result.error.message : "An error occured, please try again" });
                    // }
                    // else {
                    //     this.setState({ error: "An error occured, please try again" });
                    // }
                }
            },
            onError: (_error: any) => {
                this.setState({
                    isLoading: false,
                    // error: "An error occured, please try again"
                });
            }
        });
    }

    private submitVote = () => {
        this.setState({ show_confirm_modal: false });
        window.dispatchEvent(new CustomEvent('submit-vote-confirmed', { detail: { item_id: this.selected_item_id } }));
    }

    render() {
        return (
            <>
                {this.state.is_submitting &&
                    <div className='voting-item-dialog-cont'>
                        <div className='voting-item-dialog-modal' />
                        <div className='voting-item-dialog-content'>
                            <div className='voting-item-loading def-loading-spinner' />
                            <span className='voting-item-dialog-text'>Submitting...</span>
                        </div>
                    </div>
                }
                {this.state.show_confirm_modal &&
                    <div className='voting-item-dialog-cont'>
                        <div className='voting-item-dialog-modal' />
                        <div className='voting-item-dialog-content'>
                            <span className='voting-item-dialog-text'>Confirm Vote Submission</span>
                            <div className='voting-item-dialog-btns-cont'>
                                <div className='voting-item-dialog-btn'
                                    onClick={() => {
                                        this.selected_item_id = null;
                                        this.setState({ show_confirm_modal: false });
                                    }}>Cancel</div>
                                <div className='voting-item-dialog-btn'
                                    onClick={() => this.submitVote()}>Submit</div>
                            </div>
                        </div>
                    </div>
                }
                <div className='main-lay'>
                    <div className="main-body">
                        <div className="main-cont">
                            <div className="main-header">
                                <FaArrowAltCircleLeft className="header-back-btn"
                                    onClick={() => {
                                        // check if can bo back to previous page
                                        if (this.props.history.length > 1) {
                                            this.props.history.goBack();
                                        }
                                        else {
                                            this.props.history.push(Routes.Landing);
                                        }
                                    }} />
                                <span className="main-header-title">{this.state.page_title}</span>
                            </div>
                            <div className="main-contents">
                                {this.state.current_path === Routes.Home &&
                                    <div className='home-cont'>
                                        {this.state.current_user &&
                                            <div className="home-text-cont">
                                                <span className="home-text">Welcome</span>
                                                <span className="home-text-sec">{this.state.current_user.name}</span>
                                            </div>}
                                        <Link to={Routes.Account} className='home-btn'>Account</Link>
                                        <Link to={Routes.Candidates} className='home-btn'>Candidates</Link>
                                        <Link to={Routes.Voting} className='home-btn'>Voting</Link>
                                        <Link to={Routes.Landing} className='home-btn'>Log Out</Link>
                                    </div>
                                }
                                {this.state.current_path.startsWith(Routes.Voting) && this.state.current_user &&
                                    <VotingPage
                                        history={this.props.history}
                                        current_user={this.state.current_user}
                                        refresh_id={this.state.voting_refresh_id}
                                        onSubmit={(is_submitting: boolean) => {
                                            if (!this.didMount) return;
                                            this.setState({ is_submitting });
                                        }}
                                    />
                                }
                                {this.state.current_path.startsWith(Routes.Candidates) && this.state.current_user &&
                                    <CandidatePage
                                        history={this.props.history}
                                        refresh_id={this.state.voting_refresh_id}
                                    />
                                }
                                {this.state.current_path.startsWith(Routes.Account) && this.state.current_user &&
                                    <AccountPage
                                        history={this.props.history}
                                        refresh_id={this.state.voting_refresh_id}
                                        current_user_id={this.state.current_user.id}
                                    />
                                }
                            </div>
                            <div className="main-bottom">
                                <Link to={Routes.Landing} target="_self" className="main-bottom-item">Laibon</Link>
                                <Link to={Routes.Help} target="_blank" className="main-bottom-item">Help</Link>
                                <Link to={Routes.Privacy} target="_blank" className="main-bottom-item">Privacy</Link>
                                <Link to={Routes.Terms} target="_blank" className="main-bottom-item">Terms</Link>
                                <div className="main-bottom-item copyright-text">&copy; Laibon | 2022</div>
                                <div className="theme-btn">
                                    <i className="fas fa-moon theme-icon dark-icon" id="dark-btn" />
                                    <i className="fas fa-sun theme-icon light-icon" id="light-btn" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Main);