// import Cookies from 'universal-cookie';

import { CandidateCategories, DeviceInfo } from '../models';

import { OnResult } from './listeners';
import { UAParser } from 'ua-parser-js';
import axios from 'axios';

const host = "https://agm.imperialinnovations.co.tz/api/v1/";
export const socket_url = "ws://192.168.0.103:9000"; //"wss://mdm-socket.herokuapp.com"; // "http://192.168.100.5:3000";
export const api_key = "axiopu45yru54piegh048yruht3wp";

export default class DatabaseManager {
    // private cookies: Cookies = new Cookies();
    private cancelToken: any = axios.CancelToken;
    private source: any = this.cancelToken.source();

    public registerUser(user_Id: string, callback: OnResult<any>): void {
        const request = {
            key: api_key,
            user_id: user_Id,
            action: "event_registration",
            registration_type: 'physical', // TODO: this is only for laibon device registration ie: mobile phone, tablet etc.
        }
        try {
            const url = host + 'register?t=' + (new Date()).valueOf();
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((result: any) => {
                    // console.log("register: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e) {
            callback.onError(e);
        }
    }

    public loginUser(user_id: string, callback: OnResult<any>): void {
        const session_id = (sessionStorage.getItem('sid') ? sessionStorage.getItem('sid') : '') as string;
        const request = {
            key: api_key,
            user_id: user_id,
            session_id: session_id,
            action: 'login'
        }

        try {
            const url = host + 'activity?t=' + (new Date()).valueOf();
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // // console.log("daily_device_data: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getEventStatus(callback: OnResult<any>): void {
        const request = {
            key: api_key,
            action: "event_status"
        }
        try {
            const url = host + 'activity?t=' + (new Date()).valueOf();
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // // console.log("daily_device_data: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getUserDetails(user_id: string, callback: OnResult<any>): void {
        const request = {
            key: api_key,
            user_id: user_id,
            action: 'user_details'
        }

        try {
            const url = host + 'activity?t=' + (new Date()).valueOf();
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getVotingInfo(user_id: string, callback: OnResult<any>): void {
        const request = {
            key: api_key,
            user_id: user_id,
            action: 'voting_info'
        }

        try {
            const url = host + 'voting?t=' + (new Date()).valueOf();
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public submitVote(
        user_id: string,
        vote_category: CandidateCategories | '',
        voted_candidate_id: string,
        vote_value: string,
        //
        callback: OnResult<any>): void {

        const ua_parser = new UAParser();

        const device_type = ua_parser.getDevice().type;
        const device_model = ua_parser.getDevice().model;
        const device_vendor = ua_parser.getDevice().vendor;
        const os_name = ua_parser.getOS().name;
        const os_version = ua_parser.getOS().version;
        const browser_name = ua_parser.getBrowser().name;
        const browser_version = ua_parser.getBrowser().version;
        const browser_major = ua_parser.getBrowser().major;
        const engine_name = ua_parser.getEngine().name;
        const engine_version = ua_parser.getEngine().version;
        const cpu_architecture = ua_parser.getCPU().architecture;
        const ua_string = ua_parser.getUA();

        // TODO replace all ' with  \'

        const device_info: DeviceInfo = {
            device: {
                type: device_type ? device_type.replace(/'/g, "\\'") : '',
                model: device_model ? device_model.replace(/'/g, "\\'") : '',
                vendor: device_vendor ? device_vendor.replace(/'/g, "\\'") : '',
            },
            os: {
                name: os_name ? os_name.replace(/'/g, "\\'") : '',
                version: os_version ? os_version.replace(/'/g, "\\'") : '',
            },
            browser: {
                name: browser_name ? browser_name.replace(/'/g, "\\'") : '',
                version: browser_version ? browser_version.replace(/'/g, "\\'") : '',
                major: browser_major ? browser_major.replace(/'/g, "\\'") : '',
            },
            engine: {
                name: engine_name ? engine_name.replace(/'/g, "\\'") : '',
                version: engine_version ? engine_version.replace(/'/g, "\\'") : '',
            },
            cpu: {
                architecture: cpu_architecture ? cpu_architecture.replace(/'/g, "\\'") : '',
            },
            ua: ua_string ? ua_string.replace(/'/g, "\\'") : '',
            ip: '',
        }
        // convert to json string
        const device_info_json = JSON.stringify(device_info);

        const request = {
            key: api_key,
            user_id: user_id,
            vote_category: vote_category,
            voted_candidate_id: voted_candidate_id,
            vote_value: vote_value,
            voted_from: 'physical',
            voted_from_device: device_info_json,
            action: 'submit_vote'
        }

        console.log("> submit vote request: ", request);

        try {
            const url = host + 'voting?t=' + (new Date()).valueOf();
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    public getCandidateDetails(callback: OnResult<any>): void {
        const request = {
            key: api_key,
            action: 'candidate_list'
        }

        try {
            const url = host + 'activity?t=' + (new Date()).valueOf();
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }

    // private cancelRequests() {
    //     this.source.cancel('Operation canceled by the user.');
    //     this.source = this.cancelToken.source();
    // }

    public logoutUser(user_id: string, callback: OnResult<any>): void {
        const session_id = (sessionStorage.getItem('sid') ? sessionStorage.getItem('sid') : '') as string;
        const request = {
            key: api_key,
            user_id: user_id,
            session_id: session_id,
            action: 'logout'
        }

        try {
            const url = host + 'activity?t=' + (new Date()).valueOf();
            axios({
                url: url,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                cancelToken: this.source.token
            })
                // .then(res => res.json())
                .then((result: any) => {
                    // // console.log("daily_device_data: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    console.error("> axios error: ", err);
                    callback.onError(err);
                })
                .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                        // console.log('Request canceled', thrown);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });
        } catch (e: any) {
            callback.onError(e);
        }
    }
}