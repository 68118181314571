import './candidate-item.scss';

import { Candidate } from "../../models";
import React from "react";

type CandidateItemProps = {
    candidate: Candidate;
}

type CandidateItemState = {
    category: string;
}

export default class CandidateItem extends React.Component<CandidateItemProps, CandidateItemState> {

    private avatar: string = require('../../assets/images/avatar.png');

    constructor(props: CandidateItemProps) {
        super(props);

        let category = '';
        if (this.props.candidate.category === 'below_1') {
            category = 'Below 1% Share';
        }
        else if (this.props.candidate.category === 'between_1_and_10') {
            category = 'Between 1% and 10% Share';
        }
        else if (this.props.candidate.category === 'above_10') {
            category = 'Above 10% Share';
        }
        else if (this.props.candidate.category === 'independent_director') {
            category = 'Independent Director';
        }

        this.state = { category };

        console.log('CandidateItem: props', props);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        return (
            <div className='candidate-item'>
                <div className='candidate-item-first-lay'>
                    <div className="candidate-item-avatar-cont">
                        <div className="candidate-item-avatar-top" />
                        <img src={this.avatar} alt="avatar" className="candidate-item-avatar-img" />
                    </div>
                </div>
                <div className='candidate-item-sec-lay'>
                    <div className='candidate-item-text-lay'>
                        <span className='candidate-item-text-1'>{this.props.candidate.name}</span>
                    </div>
                    <div className='candidate-item-text-lay'>
                        {/* <span className='candidate-item-text-1'>Category: </span> */}
                        <span className='candidate-item-text-2'>{this.state.category}</span>
                    </div>
                    {/* <div className='candidate-item-text-lay'>
                        <span className='candidate-item-text-1'>Category:</span>
                        <span className='candidate-item-text-2'>{this.props.candidate.name}</span>
                    </div> */}
                </div>
            </div>
        );
    }
}