import './index.scss';
import './proxy-item.scss';

import * as H from 'history';

import { Link, match, withRouter } from 'react-router-dom';
import { User, UserProxy } from '../../models';

import DatabaseManager from '../../service';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import React from 'react';
import Routes from '../../utils/routes';
import Themer from '../../utils/themer';

// import { faker } from '@faker-js/faker';


type Props = {
    history: H.History,
    match: match,
    location: H.Location,
}

type State = {
    user_id: string;
    error: string;
    isLoading: boolean;
    isLoaded: boolean;
    //
    name: string;
    email: string;
    phone: string;
    type: string;
    proxies: UserProxy[];
}

class Registration extends React.PureComponent<Props, State> {

    private didMount: boolean = false;
    private avatar: string = require('../../assets/images/avatar.png');
    // private user: User | null = null;
    private databaseManager: DatabaseManager = new DatabaseManager();

    constructor(props: any) {
        super(props);

        // const fake_proxies: UserProxy[] = [];
        // for (let i = 0; i < 20 + 1; i++) {
        //     let object: UserProxy;
        //     const cds = faker.datatype.number({ min: 10000, max: 99999 });
        //     const random_id_str = faker.datatype.uuid().replace(/-/g, '').substring(0, 4);
        //     object = {
        //         id: cds.toString() + random_id_str,
        //         name: faker.name.findName(),
        //         email: faker.internet.email(),
        //         phone: faker.phone.phoneNumber('255#########'),
        //         client_id: cds.toString(),
        //         client_type: faker.random.arrayElement(['Company', 'Citizen']),
        //         address_1: faker.address.streetAddress(),
        //         address_2: faker.address.secondaryAddress(),
        //         address_3: faker.address.streetName(),
        //         city: faker.address.city(),
        //         contact_person: faker.name.findName(),
        //         fax_number: faker.phone.phoneNumber('255#########'),
        //         instrument: faker.random.arrayElement(['CRDB']),
        //         issuer: faker.random.arrayElement(['CRDB/I']),
        //         balance: faker.finance.amount(0, 99999999, 0, '', true),
        //         created_at: faker.date.past().getTime(),
        //         type: 'shareholder',// faker.random.arrayElement(['shareholder', 'shareholder_proxy', 'non_shareholder_proxy', 'guest']),
        //         represented: true,
        //         represented_by: '123456789',
        //         attend_type: 'Online',
        //         attended: true,
        //         attended_at: faker.date.past().getTime(),
        //     };
        //     fake_proxies.push(object);
        // }

        this.state = {
            user_id: "",
            error: "",
            isLoading: false,
            isLoaded: false,
            //
            name: "",
            email: "",
            phone: "",
            type: "",
            proxies: [],
        }

        this.onValueChange = this.onValueChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.didMount = true;
        Themer.subscribe();
        document.title = "Laibon - Device - Registration";
        window.sessionStorage.removeItem('laibon-uid');
        // setTimeout(() => {
        //     if (!this.didMount) return;
        //     this.setState({ isLoading: true });
        //     setTimeout(() => {
        //         if (!this.didMount) return;
        //         this.setState({ isLoading: false, isLoaded: true });
        //     }, 3000);
        // }, 1000);
    }

    componentWillUnmount() {
        this.didMount = false;
        Themer.unsubscribe();
    }

    onValueChange = (e: any) => {
        this.setState({ user_id: e.target.value, error: '' });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (this.state.user_id.length <= 3) {
            this.setState({ error: "Please enter a valid CDS Number" });
            setTimeout(() => {
                if (!this.didMount) return;
                this.setState({ error: "" });
            }, 1000);
            return;
        }

        this.setState({ isLoading: true, error: "" });

        this.databaseManager.registerUser(this.state.user_id, {
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                if (result && result.success && result.success.data && result.success.data.length > 0) {
                    const data = result.success.data[0];
                    const proxies: UserProxy[] = [];
                    const user: User = {
                        id: data.id ? data.id : "",
                        name: data.name ? data.name : "",
                        email: data.email ? data.email : "",
                        phone: data.phone ? data.phone : "",
                        client_id: data.client_id ? data.client_id : "",
                        client_type: data.client_type ? data.client_type : "",
                        address_1: data.address_1 ? data.address_1 : "",
                        address_2: data.address_2 ? data.address_2 : "",
                        address_3: data.address_3 ? data.address_3 : "",
                        city: data.city ? data.city : "",
                        contact_person: data.contact_person ? data.contact_person : "",
                        fax_number: data.fax_number ? data.fax_number : "",
                        instrument: data.instrument ? data.instrument : "",
                        issuer: data.issuer ? data.issuer : "",
                        balance: data.balance ? data.balance : 0,
                        created_at: data.created_at ? data.created_at : 0,
                        type: data.type ? data.type : "",
                        represented: data.represented ? data.represented : false,
                        represented_by: data.represented_by ? data.represented_by : "",
                        attend_type: data.attend_type ? data.attend_type : "",
                        attended: data.attended ? data.attended : false,
                        attended_at: data.attended_at ? data.attended_at : 0,
                        login_type: data.login_type ? data.login_type : "",
                        sessions: data.sessions ? data.sessions : [],
                        proxies: [],
                    }
                    if (data.proxies && data.proxies.length > 0) {
                        data.proxies.forEach((proxy: UserProxy) => {
                            const newProxy: UserProxy = {
                                id: proxy.id ? proxy.id : "",
                                name: proxy.name ? proxy.name : "",
                                email: proxy.email ? proxy.email : "",
                                phone: proxy.phone ? proxy.phone : "",
                                client_id: proxy.client_id ? proxy.client_id : "",
                                client_type: proxy.client_type ? proxy.client_type : "",
                                address_1: proxy.address_1 ? proxy.address_1 : "",
                                address_2: proxy.address_2 ? proxy.address_2 : "",
                                address_3: proxy.address_3 ? proxy.address_3 : "",
                                city: proxy.city ? proxy.city : "",
                                contact_person: proxy.contact_person ? proxy.contact_person : "",
                                fax_number: proxy.fax_number ? proxy.fax_number : "",
                                instrument: proxy.instrument ? proxy.instrument : "",
                                issuer: proxy.issuer ? proxy.issuer : "",
                                balance: proxy.balance ? proxy.balance : 0,
                                created_at: proxy.created_at ? proxy.created_at : 0,
                                type: proxy.type ? proxy.type : "shareholder",
                                represented: proxy.represented ? proxy.represented : false,
                                represented_by: proxy.represented_by ? proxy.represented_by : "",
                                attend_type: proxy.attend_type ? proxy.attend_type : "",
                                attended: proxy.attended ? proxy.attended : false,
                                attended_at: proxy.attended_at ? proxy.attended_at : 0,
                            }
                            proxies.push(newProxy);
                        });
                        user.proxies = proxies;
                    }
                    this.setState({
                        name: user.name,
                        email: user.email,
                        phone: user.phone,
                        type: user.type,
                        proxies: user.proxies,
                        isLoaded: true,
                    });
                }
                else {
                    if (result && result.error) {
                        this.setState({ error: result.error.message ? result.error.message : "An error occured, please try again" });
                    }
                    else {
                        this.setState({ error: "An error occured, please try again" });
                    }
                }
            },
            onError: (_error: any) => {
                this.setState({
                    isLoading: false,
                    error: "An error occured, please try again"
                    // error: typeof error === "string" ? error :
                    //   error.message && typeof error.message === "string" ?
                    //     error.message : "An error occured, please try again",
                });
            }
        });
    }

    render() {
        return (
            <div className="reg-main-lay">
                <div className="reg-body">
                    <div className="reg-cont">
                        {this.state.isLoading && <div className="loading-cont modal-shadow">
                            <div className="def-loading-spinner loading-icon" />
                        </div>}
                        <div className="out-header">
                            <FaArrowAltCircleLeft className="header-back-btn"
                                onClick={() => {
                                    // check if can bo back to previous page
                                    if (this.props.history.length > 1) {
                                        this.props.history.goBack();
                                    }
                                    else {
                                        this.props.history.push(Routes.Landing);
                                    }
                                }} />
                            <span className="out-header-title">Laibon - Device - Registration</span>
                        </div>
                        {!this.state.isLoaded && <div className="reg-form">
                            <div className="avatar-cont">
                                <div className="avatar-top" />
                                <img src={this.avatar} alt="avatar" className="avatar-img" />
                            </div>
                            <form className="field-cont" onSubmit={this.onSubmit} >
                                <input
                                    type="text"
                                    placeholder="Enter CDS Number"
                                    className="field-input"
                                    autoComplete="off"
                                    value={this.state.user_id}
                                    onChange={this.onValueChange}
                                // required
                                />
                                <button className='register-btn' type='submit'>Register</button>
                                {this.state.error.length > 0 && <div className="message-box">{this.state.error}</div>}
                            </form>
                            {/* <div className='extra-cont'>
                                <span className="extra-text">ALready registered?</span>
                                <Link to={Routes.Login} className="extra-link">Login Now</Link>
                            </div> */}
                        </div>}
                        {this.state.isLoaded &&
                            <div className="reg-out">
                                <div className="out-first-lay">
                                    <div className="avatar-cont">
                                        <div className="avatar-top" />
                                        <img src={this.avatar} alt="avatar" className="avatar-img" />
                                    </div>
                                    <div className="out-first-text-lay">
                                        <div className="out-text-cont">
                                            <span className="out-title">CDS</span>
                                            <span className="out-info" >{this.state.user_id}</span>
                                        </div>
                                        <div className="out-text-cont">
                                            <span className="out-title">Name</span>
                                            <span className="out-info" >{this.state.name}</span>
                                        </div>
                                        <div className="out-text-cont">
                                            <span className="out-title">Phone</span>
                                            <span className="out-info" >{this.state.phone}</span>
                                        </div>
                                        <div className="out-text-cont">
                                            <span className="out-title">Email</span>
                                            <span className="out-info" >{this.state.email}</span>
                                        </div>
                                        <div className="out-text-cont">
                                            <span className="out-title">Type</span>
                                            <span className="out-info" >{this.state.type}</span>
                                        </div>
                                    </div>
                                </div>
                                <Link to={Routes.Login} className="continue-btn">Continue</Link>
                                {this.state.proxies.length > 0 &&
                                    <div className="proxies-cont">
                                        <div className="body-info">
                                            <span>{this.state.proxies.length + " Proxy(s)"}</span>
                                        </div>
                                        {
                                            this.state.proxies.map((proxy: UserProxy, index: number) => {
                                                return (
                                                    <ProxyItem
                                                        key={index}
                                                        proxy={proxy}
                                                    />
                                                );
                                            })
                                        }
                                    </div>}
                            </div>
                        }
                        <div className="out-bottom">
                            <Link to={Routes.Landing} target="_self" className="out-bottom-item">Laibon</Link>
                            <Link to={Routes.Help} target="_blank" className="out-bottom-item">Help</Link>
                            <Link to={Routes.Privacy} target="_blank" className="out-bottom-item">Privacy</Link>
                            <Link to={Routes.Terms} target="_blank" className="out-bottom-item">Terms</Link>
                            <div className="out-bottom-item copyright-text">&copy; Laibon | 2022</div>
                            <div className="theme-btn">
                                <i className="fas fa-moon theme-icon dark-icon" id="dark-btn" />
                                <i className="fas fa-sun theme-icon light-icon" id="light-btn" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Registration);

type ProxyItemProps = {
    proxy: UserProxy,
}

type ProxyItemState = {

}

class ProxyItem extends React.PureComponent<ProxyItemProps, ProxyItemState> {

    private avatar: string = require('../../assets/images/avatar.png');

    // constructor(props: any) {
    //     super(props);
    // }

    render() {
        return (
            <div className="proxy-item-cont">
                <div className="avatar-cont">
                    <div className="avatar-top" />
                    <img src={this.avatar} alt="avatar" className="avatar-img" />
                </div>
                <div className="out-first-text-lay">
                    <div className="out-text-cont">
                        <span className="out-title">CDS</span>
                        <span className="out-info" >{this.props.proxy.id}</span>
                    </div>
                    <div className="out-text-cont">
                        <span className="out-title">Name</span>
                        <span className="out-info" >{this.props.proxy.name}</span>
                    </div>
                    <div className="out-text-cont">
                        <span className="out-title">Phone</span>
                        <span className="out-info" >{this.props.proxy.phone}</span>
                    </div>
                    <div className="out-text-cont">
                        <span className="out-title">Email</span>
                        <span className="out-info" >{this.props.proxy.email}</span>
                    </div>
                    <div className="out-text-cont">
                        <span className="out-title">Type</span>
                        <span className="out-info" >{
                            this.props.proxy.type === 'shareholder' ? 'Shareholder' :
                                this.props.proxy.type === 'shareholder_proxy' ? 'Shareholder Proxy' :
                                    this.props.proxy.type === 'non_shareholder_proxy' ? 'Non-Shareholder Proxy' :
                                        'Guest'
                        }</span>
                    </div>
                </div>
            </div>
        );
    }
}