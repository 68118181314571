import './voting-item.scss';

import { Candidate, CandidateCategories, UserVotingInfo } from "../../models";
import * as uuid from 'uuid';

import DatabaseManager from '../../service';
import React from "react";

type VotingCandidate = {
    candidate_id: string;
    candidate_name: string;
    selected: boolean;
}

type VotingItemProps = {
    index: string;
    voting: UserVotingInfo;
    onSubmit: (is_submitting: boolean) => void;
}

type VotingItemState = {
    category_name: string;
    category_candidates: VotingCandidate[];
    independent_candidates: VotingCandidate[];
    category_submission_message: string;
    independent_submission_message: string;
}

export default class VotingItem extends React.Component<VotingItemProps, VotingItemState> {

    private avatar: string = require('../../assets/images/avatar.png');
    private didMount: boolean = false;
    private databaseManager: DatabaseManager = new DatabaseManager();
    private selected_for_independent: boolean = false;
    private item_id: string = uuid.v4();

    constructor(props: VotingItemProps) {
        super(props);

        let category_name = '';
        switch (this.props.voting.voting_category) {
            case 'below_1':
                category_name = 'Below 1%';
                break;
            case 'between_1_and_10':
                category_name = 'Between 1% and 10%';
                break;
            case 'above_10':
                category_name = 'Above 10%';
                break;
        }

        const category_candidates: VotingCandidate[] = [];
        // add all props.voting.candidates who are not independent
        this.props.voting.candidates.forEach((candidate: Candidate) => {
            if (candidate.category !== 'independent_director') {
                category_candidates.push({
                    candidate_id: candidate.id,
                    candidate_name: candidate.name,
                    selected: false,
                });
            }
        });
        // add default category candidate, this is for the case that user haven't voted for any category candidate
        category_candidates.push({
            candidate_id: 'abstained',
            candidate_name: 'Independent',
            selected: true,
        });

        const independent_candidates: VotingCandidate[] = [];
        // add all props.voting.candidates who are independent
        this.props.voting.candidates.forEach((candidate: Candidate) => {
            if (candidate.category === 'independent_director') {
                independent_candidates.push({
                    candidate_id: candidate.id,
                    candidate_name: candidate.name,
                    selected: false,
                });
            }
        });
        // add default independent candidate, this is for the case that user haven't voted for any independent candidate
        independent_candidates.push({
            candidate_id: 'abstained',
            candidate_name: 'Independent',
            selected: true,
        });

        this.state = {
            category_name,
            category_candidates,
            independent_candidates,
            category_submission_message: '',
            independent_submission_message: '',
        }
    }

    componentDidMount() {
        this.didMount = true;
        window.addEventListener('submit-vote-confirmed', (event: any) => {
            if (!this.didMount) return;
            const item_id = event.detail.item_id ? event.detail.item_id : '';
            console.log('submit-vote-confirmed: received');
            if (item_id === this.item_id) {
                this.submitVote(this.selected_for_independent);
            }
        });
    }

    componentWillUnmount() {
        this.didMount = false;
        this.props.onSubmit(false);
        window.removeEventListener('submit-vote-confirmed', () => { });
    }

    private onChange(for_independent: boolean, candidate_id: string) {
        if (for_independent) {
            // console.log('onChange: independent', candidate_id);
            const independent_candidates = this.state.independent_candidates;
            independent_candidates.forEach((candidate: VotingCandidate) => {
                candidate.selected = candidate.candidate_id === candidate_id;
            });
            // const independent_candidates = this.state.independent_candidates.map((candidate: VotingCandidate) => {
            //     if (candidate.candidate_id === candidate_id) {
            //         candidate.selected = !e.target.checked;
            //     }
            //     return candidate;
            // });
            this.setState({ independent_candidates });
        }
        else {
            // console.log('onChange: category', candidate_id);
            const category_candidates = this.state.category_candidates;
            category_candidates.forEach((candidate: VotingCandidate) => {
                candidate.selected = candidate.candidate_id === candidate_id;
            });
            // const category_candidates = this.state.category_candidates.map((candidate: VotingCandidate) => {
            //     if (candidate.candidate_id === candidate_id) {
            //         candidate.selected = e.target.checked;
            //     }
            //     return candidate;
            // });
            this.setState({ category_candidates });
        }
        // this.forceUpdate();
    }

    private onSubmit = (for_independent: boolean) => {
        this.selected_for_independent = for_independent;
        window.dispatchEvent(new CustomEvent('confirm-submit-vote', { detail: { item_id: this.item_id } }));
    }

    private submitVote = (for_independent: boolean) => {
        let selected_candidate_id = '';
        if (for_independent) {
            const independent_candidates = this.state.independent_candidates;
            independent_candidates.forEach((candidate: VotingCandidate) => {
                if (candidate.selected) {
                    selected_candidate_id = candidate.candidate_id;
                }
            });
        }
        else {
            const category_candidates = this.state.category_candidates;
            category_candidates.forEach((candidate: VotingCandidate) => {
                if (candidate.selected) {
                    selected_candidate_id = candidate.candidate_id;
                }
            });
        }
        if (selected_candidate_id === '') return;

        let vote_value = '';
        let voted_candidate_id = '';
        if (selected_candidate_id === 'abstained') {
            vote_value = 'abstain';
            voted_candidate_id = '';
        }
        else {
            vote_value = 'yes';
            voted_candidate_id = selected_candidate_id;
        }
        let voting_category: CandidateCategories | '' = '';
        if (for_independent) {
            voting_category = 'independent_director';
        } else {
            switch (this.props.voting.voting_category) {
                case 'below_1':
                    voting_category = 'below_1';
                    break;
                case 'between_1_and_10':
                    voting_category = 'between_1_and_10';
                    break;
                case 'above_10':
                    voting_category = 'above_10';
                    break;
            }
        }
        const user_id = this.props.voting.user_id;
        this.props.onSubmit(true);

        this.databaseManager.submitVote(
            user_id,
            voting_category,
            voted_candidate_id,
            vote_value, {
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.props.onSubmit(false);
                if (result && result.success) {
                    if (result.success.message) {
                        if (for_independent) {
                            this.setState({ independent_submission_message: result.success.message });
                        } else {
                            this.setState({ category_submission_message: result.success.message });
                        }
                    }
                }
                else {
                    if (result && result.error && result.error.message) {
                        if (for_independent) {
                            this.setState({ independent_submission_message: result.error.message });
                        }
                        else {
                            this.setState({ category_submission_message: result.error.message });
                        }
                    }
                }
            },
            onError: (error: any) => {
                if (!this.didMount) return;
                this.props.onSubmit(false);
                console.log('submitVote error: ', error);
                if (for_independent) {
                    this.setState({ independent_submission_message: 'Error submitting vote. Please refresh this page and try again' });
                }
                else {
                    this.setState({ category_submission_message: 'Error submitting vote. Please refresh this page and try again' });
                }
            }
        });
    }

    render() {
        return (
            <div key={'voting-item-' + this.props.index} className='voting-item'>
                <div className='voting-item-header'>
                    <div className='voting-item-header-sub'>
                        <span className='voting-item-title-text-1'>You are voting as:</span>
                        <span className='voting-item-title-text-2'>{this.props.voting.user_name}</span>
                    </div>
                    <div className='voting-item-header-sub'>
                        <span className='voting-item-title-text-1'>Category:</span>
                        <span className='voting-item-title-text-2'>{this.state.category_name}</span>
                    </div>
                </div>
                {/* <div className='voting-item-line' /> */}
                {this.state.category_candidates.length > 1 &&
                    <div className='voting-item-cnd-list-cont'>
                        <div className='voting-item-cnd-header'>
                            <span className='voting-item-cnd-text'>{'Candidates (' + (this.state.category_candidates.length - 1) + ')'}</span>
                        </div>
                        {this.state.category_submission_message.length > 0 &&
                            <div className='voting-item-cdn-message-cont'>
                                <span className='voting-item-cdn-message-text'>{this.state.category_submission_message}</span>
                            </div>
                        }
                        {this.state.category_submission_message.length === 0 &&
                            <>
                                <div className='voting-item-cnd-body'>
                                    {this.state.category_candidates.map((c: VotingCandidate, index: number) => {
                                        return (
                                            <div className={c.selected ? 'voting-item-cnd-cont cnd-selected' : 'voting-item-cnd-cont'}
                                                key={c.candidate_id + '-' + index}
                                                onClick={() => this.onChange(false, c.candidate_id)}>
                                                <div className='voting-item-cnd'>
                                                    {c.candidate_id === 'abstained' &&
                                                        <div className='voting-item-cnd-abs'>
                                                            <span className='voting-item-cnd-abs-text'>Not vote</span>
                                                        </div>
                                                    }
                                                    {c.candidate_id !== 'abstained' &&
                                                        <>
                                                            <div className="voting-item-cnd-avatar-cont">
                                                                <div className="voting-item-cnd-avatar-top" />
                                                                <img src={this.avatar} alt="avatar" className="voting-item-cnd-avatar-img" />
                                                            </div>
                                                            <div className='voting-item-cnd-name'>{c.candidate_name}</div>
                                                        </>
                                                    }
                                                    <input
                                                        tabIndex={-1}
                                                        type='radio'
                                                        name={'radio-category-candidate-of-' + this.props.index}
                                                        id={'radio-category-candidate-of-' + this.props.index + '-' + index}
                                                        checked={c.selected}
                                                        value={c.selected ? 1 : 0}
                                                        readOnly={true}
                                                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(false, e, c.candidate_id)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className='voting-item-submit-btn' onClick={() => this.onSubmit(false)}>Submit</div>
                            </>
                        }
                    </div>
                }
                {this.state.independent_candidates.length > 1 &&
                    <div className='voting-item-cnd-list-cont'>
                        <div className='voting-item-cnd-header with-line-top'>
                            <span className='voting-item-cnd-text'>{'Independent Directors (' + (this.state.independent_candidates.length - 1) + ')'}</span>
                        </div>
                        {this.state.independent_submission_message.length > 0 &&
                            <div className='voting-item-cdn-message-cont'>
                                <span className='voting-item-cdn-message-text'>{this.state.independent_submission_message}</span>
                            </div>
                        }
                        {this.state.independent_submission_message.length === 0 &&
                            <>
                                <div className='voting-item-cnd-body'>
                                    {this.state.independent_candidates.map((c: VotingCandidate, index: number) => {
                                        return (
                                            <div className={c.selected ? 'voting-item-cnd-cont cnd-selected' : 'voting-item-cnd-cont'}
                                                key={c.candidate_id + '-' + index}
                                                onClick={() => this.onChange(true, c.candidate_id)}>
                                                <div className='voting-item-cnd'>
                                                    {c.candidate_id === 'abstained' &&
                                                        <div className='voting-item-cnd-abs'>
                                                            <span className='voting-item-cnd-abs-text'>Not vote</span>
                                                        </div>
                                                    }
                                                    {c.candidate_id !== 'abstained' &&
                                                        <>
                                                            <div className="voting-item-cnd-avatar-cont">
                                                                <div className="voting-item-cnd-avatar-top" />
                                                                <img src={this.avatar} alt="avatar" className="voting-item-cnd-avatar-img" />
                                                            </div>
                                                            <div className='voting-item-cnd-name'>{c.candidate_name}</div>
                                                        </>
                                                    }
                                                    <input
                                                        tabIndex={-1}
                                                        type='radio'
                                                        name={'radio-independent-candidate-of-' + this.props.index}
                                                        id={'radio-independent-candidate-of-' + this.props.index + '-' + index}
                                                        checked={c.selected}
                                                        value={c.selected ? 1 : 0}
                                                        readOnly={true}
                                                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(true, e, c.candidate_id)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className='voting-item-submit-btn' onClick={() => this.onSubmit(true)}>Submit</div>
                            </>
                        }
                    </div>
                }
            </div>
        );
    }
}