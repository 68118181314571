import './index.scss';

import * as H from 'history';

import { Link, match, withRouter } from 'react-router-dom';

import AuthContext from '../../service/context';
import DatabaseManager from '../../service';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import React from 'react';
import Routes from '../../utils/routes';
import { SessionUser } from '../../models';
import Themer from '../../utils/themer';

type Props = {
  history: H.History,
  match: match,
  location: H.Location,
}

type State = {
  user_id: string;
  error: string;
  isLoading: boolean;
}

class Login extends React.PureComponent<Props, State> {

  private didMount: boolean = false;
  private avatar: string = require('../../assets/images/avatar.png');
  private databaseManager: DatabaseManager = new DatabaseManager();
  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      user_id: "",
      error: "",
      isLoading: false,
    }

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.sessionStorage.removeItem('laibon-uid');
    // const user_id = sessionStorage.getItem('laibon-uid');
    // if (user_id) {
    //   this.context.setIsAuthenticated(true);
    //   let { from }: any = this.props.location.state || { from: { pathname: Routes.Home } };
    //   this.props.history.replace(from);
    // }
    this.didMount = true;
    Themer.subscribe();
    document.title = "Laibon - Device - Login";
  }

  componentWillUnmount() {
    this.didMount = false;
    Themer.unsubscribe();
  }

  onValueChange = (e: any) => {
    this.setState({ user_id: e.target.value, error: '' });
  }

  onSubmit = (e: any) => {
    e.preventDefault();
    if (this.state.user_id.length <= 3) {
      this.setState({ error: "Please enter a valid CDS Number" });
      setTimeout(() => {
        if (!this.didMount) return;
        this.setState({ error: "" });
      }, 1000);
      return;
    }

    this.setState({ isLoading: true, error: "" });

    this.databaseManager.loginUser(this.state.user_id, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ isLoading: false });
        if (result && result.success && result.success.data && result.success.data.length > 0) {
          const data = result.success.data[0];
          const sessionUser: SessionUser = {
            id: data.id ? data.id : "",
            name: data.name ? data.name : "",
            attended_at: data.attended_at ? data.attended_at : 0,
            login_type: data.login_type ? data.login_type : "",
            current_session_id: data.current_session_id ? data.current_session_id : "",
            sessions: data.sessions ? data.sessions : "",
          }
          sessionStorage.setItem("laibon-uid", sessionUser.id);

          this.context.setIsAuthenticated(true);
          let { from }: any = this.props.location.state || { from: { pathname: Routes.Home } };
          this.props.history.replace(from);
        }
        else {
          if (result && result.error) {
            this.setState({ error: result.error.message ? result.error.message : "An error occured, please try again" });
          }
          else {
            this.setState({ error: "An error occured, please try again" });
          }
        }
      },
      onError: (_error: any) => {
        this.setState({
          isLoading: false,
          error: "An error occured, please try again"
          // error: typeof error === "string" ? error :
          //   error.message && typeof error.message === "string" ?
          //     error.message : "An error occured, please try again",
        });
      }
    });
  }

  render() {
    return (
      <div className="login-main-lay">
        <div className="login-body">
          <div className="login-cont">
            {this.state.isLoading && <div className="loading-cont modal-shadow">
              <div className="def-loading-spinner loading-icon" />
            </div>}
            <div className="out-header">
              <FaArrowAltCircleLeft className="header-back-btn"
                onClick={() => {
                  // check if can bo back to previous page
                  if (this.props.history.length > 1) {
                    this.props.history.goBack();
                  }
                  else {
                    this.props.history.push(Routes.Landing);
                  }
                }} />
              <span className="out-header-title">Laibon - Device - Log in</span>
            </div>
            <div className="login-form">
              <div className="avatar-cont">
                <div className="avatar-top" />
                <img src={this.avatar} alt="avatar" className="avatar-img" />
              </div>
              <form className="field-cont" onSubmit={this.onSubmit} >
                <input
                  type="text"
                  placeholder="Enter CDS Number"
                  className="field-input"
                  autoComplete="off"
                  value={this.state.user_id}
                  onChange={this.onValueChange}
                // required
                />
                <button className='login-btn' type='submit'>Login</button>
                {this.state.error.length > 0 && <div className="message-box">{this.state.error}</div>}
              </form>
              {/* <div className='extra-cont'>
                <span className="extra-text">Haven't registered yet?</span>
                <Link to={Routes.Register} className="extra-link">Register Now</Link>
              </div> */}
            </div>
            <div className="out-bottom">
              <Link to={Routes.Landing} target="_self" className="out-bottom-item">Laibon</Link>
              <Link to={Routes.Help} target="_blank" className="out-bottom-item">Help</Link>
              <Link to={Routes.Privacy} target="_blank" className="out-bottom-item">Privacy</Link>
              <Link to={Routes.Terms} target="_blank" className="out-bottom-item">Terms</Link>
              <div className="out-bottom-item copyright-text">&copy; Laibon | 2022</div>
              <div className="theme-btn">
                <i className="fas fa-moon theme-icon dark-icon" id="dark-btn" />
                <i className="fas fa-sun theme-icon light-icon" id="light-btn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);