import './index.scss';

import * as H from "history";

import { User, UserProxy } from "../../models";

import DatabaseManager from "../../service";
import { FaTimes } from "react-icons/fa";
import React from "react";

type AccountPageProps = {
    history: H.History;
    current_user_id: string;
    refresh_id: number;
}
type AccountPageState = {
    isLoading: boolean;
    user: User | null;
    user_type: string;
    proxies: UserProxy[];
    error: string | null;
}

export default class AccountPage extends React.PureComponent<AccountPageProps, AccountPageState> {

    private didMount: boolean = false;
    private refresh_id: number = 0;
    private avatar: string = require('../../assets/images/avatar.png');
    private databaseManager: DatabaseManager = new DatabaseManager();

    constructor(props: AccountPageProps) {
        super(props);

        this.state = {
            isLoading: true,
            user: null,
            user_type: '',
            proxies: [],
            error: null,
        }
    }

    componentDidMount() {
        this.didMount = true;
        this.refresh_id = this.props.refresh_id;
        this.getUserDetails();
    }

    componentDidUpdate(prevProps: AccountPageProps, _prevState: AccountPageState) {
        if (this.refresh_id !== prevProps.refresh_id) {
            this.refresh_id = this.props.refresh_id;
            // update functions from here
            this.getUserDetails();
        }
    }

    componentWillUnmount() {
        this.didMount = false;
    }

    private getUserDetails() {
        this.setState({ isLoading: true });
        this.databaseManager.getUserDetails(this.props.current_user_id, {
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                if (result && result.success && result.success.data && result.success.data.length > 0) {
                    const data = result.success.data[0];
                    const proxies: UserProxy[] = [];
                    const user: User = {
                        id: data.id ? data.id : "",
                        name: data.name ? data.name : "",
                        email: data.email ? data.email : "",
                        phone: data.phone ? data.phone : "",
                        client_id: data.client_id ? data.client_id : "",
                        client_type: data.client_type ? data.client_type : "",
                        address_1: data.address_1 ? data.address_1 : "",
                        address_2: data.address_2 ? data.address_2 : "",
                        address_3: data.address_3 ? data.address_3 : "",
                        city: data.city ? data.city : "",
                        contact_person: data.contact_person ? data.contact_person : "",
                        fax_number: data.fax_number ? data.fax_number : "",
                        instrument: data.instrument ? data.instrument : "",
                        issuer: data.issuer ? data.issuer : "",
                        balance: data.balance ? data.balance : 0,
                        created_at: data.created_at ? data.created_at : 0,
                        type: data.type ? data.type : "",
                        represented: data.represented ? data.represented : false,
                        represented_by: data.represented_by ? data.represented_by : "",
                        attend_type: data.attend_type ? data.attend_type : "",
                        attended: data.attended ? data.attended : false,
                        attended_at: data.attended_at ? data.attended_at : 0,
                        login_type: data.login_type ? data.login_type : "",
                        sessions: data.sessions ? data.sessions : [],
                        proxies: [],
                    }
                    if (data.proxies && data.proxies.length > 0) {
                        data.proxies.forEach((proxy: UserProxy) => {
                            const newProxy: UserProxy = {
                                id: proxy.id ? proxy.id : "",
                                name: proxy.name ? proxy.name : "",
                                email: proxy.email ? proxy.email : "",
                                phone: proxy.phone ? proxy.phone : "",
                                client_id: proxy.client_id ? proxy.client_id : "",
                                client_type: proxy.client_type ? proxy.client_type : "",
                                address_1: proxy.address_1 ? proxy.address_1 : "",
                                address_2: proxy.address_2 ? proxy.address_2 : "",
                                address_3: proxy.address_3 ? proxy.address_3 : "",
                                city: proxy.city ? proxy.city : "",
                                contact_person: proxy.contact_person ? proxy.contact_person : "",
                                fax_number: proxy.fax_number ? proxy.fax_number : "",
                                instrument: proxy.instrument ? proxy.instrument : "",
                                issuer: proxy.issuer ? proxy.issuer : "",
                                balance: proxy.balance ? proxy.balance : 0,
                                created_at: proxy.created_at ? proxy.created_at : 0,
                                type: proxy.type ? proxy.type : "shareholder",
                                represented: proxy.represented ? proxy.represented : false,
                                represented_by: proxy.represented_by ? proxy.represented_by : "",
                                attend_type: proxy.attend_type ? proxy.attend_type : "",
                                attended: proxy.attended ? proxy.attended : false,
                                attended_at: proxy.attended_at ? proxy.attended_at : 0,
                            }
                            proxies.push(newProxy);
                        });
                        user.proxies = proxies;
                    }
                    let user_type = '';
                    if (user.type === 'shareholder') user_type = 'Shareholder';
                    else if (user.type === 'shareholder_proxy') user_type = 'Shareholder Proxy';
                    else if (user.type === 'non_shareholder_proxy') user_type = 'Non-Shareholder Proxy';
                    else if (user.type === 'guest') user_type = 'Guest';
                    this.setState({
                        user: user,
                        proxies: proxies,
                        user_type: user_type,
                        error: null,
                    });
                } else {
                    if (result && result.error && result.error.message) {
                        this.setState({ error: result.error });
                    } else {
                        this.setState({ error: 'Something went wrong. please refresh page.' });
                    }
                }
            },
            onError: (error: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                console.log('getUserDetails: error', error);
            }
        });
    }

    render() {
        return (
            <div className='account-lay'>
                <div className='account-lay-inner'>
                    <div className='account-lay-inner-body'>
                        {this.state.isLoading && <div className='account-loading-icon def-loading-spinner' />}
                        {this.state.error &&
                            <div className='account-lay-error'>
                                <span className='account-lay-error-text'>{this.state.error}</span>
                            </div>
                        }
                        {this.state.user &&
                            <>
                                <div className='account-first-lay'>
                                    <div className="account-avatar-cont">
                                        <div className="account-avatar-top" />
                                        <img src={this.avatar} alt="avatar" className="account-avatar-img" />
                                    </div>
                                    <div className='account-text-lay'>
                                        <span className='account-text-name'>{this.state.user.name}</span>
                                    </div>
                                    {this.state.user.email &&
                                        <div className='account-text-lay'>
                                            <span className='account-text-1'>Email:</span>
                                            <span className='account-text-2'>{this.state.user.email}</span>
                                        </div>
                                    }
                                    {this.state.user.phone &&
                                        <div className='account-text-lay'>
                                            <span className='account-text-1'>Phone:</span>
                                            <span className='account-text-2'>{this.state.user.phone}</span>
                                        </div>
                                    }
                                    {this.state.user.id &&
                                        <div className='account-text-lay'>
                                            <span className='account-text-1'>CDS:</span>
                                            <span className='account-text-2'>{this.state.user.id}</span>
                                        </div>
                                    }
                                    <div className='account-text-lay'>
                                        <span className='account-text-1'>Registered As:</span>
                                        <span className='account-text-2'>{this.state.user_type}</span>
                                    </div>
                                </div>
                                {this.state.proxies.length > 0 &&
                                    <div className='account-proxies-lay'>
                                        <span className='account-proxies-title'>{'Proxy(s) ' + this.state.proxies.length}</span>
                                        {this.state.proxies.map((proxy: UserProxy, index: number) => {
                                            return (
                                                <ProxyItem
                                                    key={'proxy-' + index}
                                                    proxy={proxy}
                                                />
                                            );
                                        })}
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

type ProxyItemProps = {
    proxy: UserProxy,
}

type ProxyItemState = {

}

class ProxyItem extends React.PureComponent<ProxyItemProps, ProxyItemState> {

    private avatar: string = require('../../assets/images/avatar.png');

    render() {
        return (
            <div className="proxy-item">
                <div className="account-avatar-cont">
                    <div className="account-avatar-top" />
                    <img src={this.avatar} alt="avatar" className="account-avatar-img" />
                </div>
                <div className='account-text-lay'>
                    <span className='account-text-name'>{this.props.proxy.name}</span>
                </div>
                {this.props.proxy.email &&
                    <div className='account-text-lay'>
                        <span className='account-text-1'>Email:</span>
                        <span className='account-text-2'>{this.props.proxy.email}</span>
                    </div>
                }
                {this.props.proxy.phone &&
                    <div className='account-text-lay'>
                        <span className='account-text-1'>Phone:</span>
                        <span className='account-text-2'>{this.props.proxy.phone}</span>
                    </div>
                }
                {this.props.proxy.id &&
                    <div className='account-text-lay'>
                        <span className='account-text-1'>CDS:</span>
                        <span className='account-text-2'>{this.props.proxy.id}</span>
                    </div>
                }
                <div className='account-text-lay'>
                    <span className='account-text-1'>Type:</span>
                    <span className='account-text-2'>
                        {this.props.proxy.type === 'shareholder' ? 'Shareholder' :
                            this.props.proxy.type === 'shareholder_proxy' ? 'Shareholder Proxy' :
                                this.props.proxy.type === 'non_shareholder_proxy' ? 'Non-Shareholder Proxy' :
                                    'Guest'
                        }
                    </span>
                </div>
            </div>
        );
    }
}