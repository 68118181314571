import './index.scss';

import * as H from 'history';

import { Candidate } from '../../models';
import CandidateItem from './candidate-item';
import DatabaseManager from '../../service';
import { FaTimes } from 'react-icons/fa';
import React from 'react';

type CandidatePageProps = {
    history: H.History;
    refresh_id: number;
}

type CandidatePageState = {
    error: string | null;
    isLoading: boolean;
    candidates: Candidate[];
}

class CandidatePage extends React.Component<CandidatePageProps, CandidatePageState> {

    private didMount: boolean = false;
    private refresh_id: number = 0;
    private databaseManager: DatabaseManager = new DatabaseManager();

    constructor(props: CandidatePageProps) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            candidates: [],
        }
    }

    componentDidMount() {
        this.didMount = true;
        this.refresh_id = this.props.refresh_id;
        this.getCandidates();
    }

    componentDidUpdate(prevProps: CandidatePageProps, _prevState: CandidatePageState) {
        if (this.refresh_id !== prevProps.refresh_id) {
            this.refresh_id = this.props.refresh_id;
            // update functions from here
            this.getCandidates();
        }
    }

    componentWillUnmount() {
        this.didMount = false;
    }

    private getCandidates() {
        this.setState({ isLoading: true });
        this.databaseManager.getCandidateDetails({
            onResult: (result: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                if (result && result.success) {
                    if (result.success.data && result.success.data.length > 0) {
                        const candidates: Candidate[] = [];
                        for (let i = 0; i < result.success.data.length; i++) {
                            const candidate: Candidate = result.success.data[i];
                            candidates.push(candidate);
                        }
                        this.setState({ candidates: candidates, error: null });
                    } else {
                        this.setState({ error: 'No candidate found' });
                    }
                }
                else {
                    if (result.error && result.error.message) {
                        this.setState({ error: result.error.message });
                    }
                }
            },
            onError: (error: any) => {
                if (!this.didMount) return;
                this.setState({ isLoading: false });
                console.log('getCandidateDetails: error', error);
            }
        });
    }

    render() {
        return (
            <div className='candidates-lay'>
                <div className='candidates-lay-inner'>
                    <div className='candidates-lay-inner-body'>
                        {this.state.isLoading && <div className='candidates-loading-icon def-loading-spinner' />}
                        {this.state.error && <div className='candidates-lay-error'>
                            <span className='candidates-lay-error-text'>{this.state.error}</span>
                        </div>}
                        {this.state.candidates.map((candidate: Candidate, index: number) => {
                            return (
                                <CandidateItem
                                    key={'candidate-' + index}
                                    candidate={candidate}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default CandidatePage;

